@function toRem($a) {
    @return ($a/37.5) + rem;
}

.loading{
    width: toRem(375);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #171c1d;
    img{
        width: toRem(120);
        height: toRem(90);
        transform: translateY(-50%);
    }
}